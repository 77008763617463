import React from 'react';
import classnames from 'classnames';
import loadable from '@loadable/component';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStars } from '@fortawesome/pro-light-svg-icons';

import useStyles from './styles';

const Grid = loadable(() => import('@material-ui/core/Grid'));
const Typography = loadable(() => import('@material-ui/core/Typography'));

const SilverBenefits = loadable(() => import('components/subscription-benefits/silver'));

const SilverBenefitsInfo = () => {
  const styles = useStyles();

  return (
        <>
        <Grid item lg={8} md={10} sm={10} xs={12}>
          <Typography
            variant="h4"
            component="h2"
            className={classnames(styles.headerBlue, styles.fourTopBotMargin)}
            align="center">
            Silver Subscription
            <FontAwesomeIcon icon={faStars} size="lg" className={styles.silverIcon}/>
            <Typography
              variant="caption"
              component="p"
              align="right"
              className={styles.headerSubText}>
              Ideal for businesses with an established and expanding clientele
            </Typography>
          </Typography>
        </Grid>
        <Grid item lg={8} md={8} sm={8} xs={10}>
          <div className={styles.fourBotMargin}>
            <SilverBenefits highlights />
          </div>
        </Grid>
        </>
  );
};

export default SilverBenefitsInfo;
